import { MainMenuEnum, MainMenuRouteEnum } from '../enums/navigation.enum';
import { UserPositionEnum } from '../enums/user-position.enum';
import { MainMenuModel } from '../models/navigation.model';

export const getMainMenu = (userProfile: any): MainMenuModel[] => [
  {
    id: MainMenuEnum.Clients,
    name: 'Customers',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Clients,
    show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    icon: 'customers-icon',
    subMenu: [
      {
        id: MainMenuEnum.Clients,
        name: 'Customers List',
        hasSidebar: false,
        route: MainMenuRouteEnum.Clients,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
      id: MainMenuEnum.PendingUsers,
      name: 'Pending Users',
      hasSidebar: false,
      route: MainMenuRouteEnum.PendingUsers,
      show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    },
    {
      id: MainMenuEnum.ZonelessHomes,
      name: 'Zoneless Homes',
      hasSidebar: false,
      route: MainMenuRouteEnum.ZonelessHomes,
      show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    },
  ]
  },
  {
    id: MainMenuEnum.Scheduler,
    name: 'Scheduler',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Scheduler,
    show: true,
    icon: 'calendar-header-menu',
    subMenu: [
      {
        id: MainMenuEnum.Zones,
        name: 'Zones',
        hasSidebar: false,
        route: MainMenuRouteEnum.Zones,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
        },
      {
        id: MainMenuEnum.Scheduler,
        name: 'Scheduler System',
        hasSidebar: false,
        route: MainMenuRouteEnum.Scheduler,
        show: true,
      },
      {
        id: MainMenuEnum.DailyTasks,
        name: 'Daily Tasks',
        hasSidebar: false,
        route: MainMenuRouteEnum.DailyTasks,
        show: true,
      },
    ]
  },
  {
    id: MainMenuEnum.GarbageCompanies,
    name: 'Company',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.GarbageCompanies,
    show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    icon: 'company-header-menu',
    subMenu: [
      {
        id: MainMenuEnum.GarbageCompanies,
        name: 'Garbage Companies',
        hasSidebar: false,
        route: MainMenuRouteEnum.GarbageCompanies,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
        id: MainMenuEnum.Employees,
        name: 'Employees',
        hasSidebar: false,
        route: MainMenuRouteEnum.Employees,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
      id: MainMenuEnum.Trucks,
      name: 'Trucks',
      hasSidebar: false,
      route: MainMenuRouteEnum.Trucks,
      show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      }
    ]
  },
  {
    id: MainMenuEnum.Offers,
    name: 'Finance',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Offers,
    show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    icon: 'finance-header-menu',
    subMenu: [
      {
        id: MainMenuEnum.Offers,
        name: 'Offers',
        hasSidebar: false,
        route: MainMenuRouteEnum.Offers,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
        id: MainMenuEnum.Billing,
        name: 'Billing',
        hasSidebar: false,
        route: MainMenuRouteEnum.Billing,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
      id: MainMenuEnum.Coupons,
      name: 'Coupons',
      hasSidebar: false,
      route: MainMenuRouteEnum.Coupons,
      show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      }
    ]
  },
  {
    id: MainMenuEnum.ASettings,
    name: 'Settings',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.ASettings,
    show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    icon: 'settings-header-menu',
    subMenu: [
      {
        id: MainMenuEnum.ASettings,
        name: 'Settings',
        hasSidebar: false,
        route: MainMenuRouteEnum.ASettings,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
        id: MainMenuEnum.Checkout,
        name: 'Checkout',
        hasSidebar: false,
        route: MainMenuRouteEnum.Checkout,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
    ]
  },
  {
    id: MainMenuEnum.Analytics,
    name: 'Analytics',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Analytics,
    show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    icon: 'analytics-header-menu',
    subMenu: [
      {
        id: MainMenuEnum.Analytics,
        name: 'Analytics',
        hasSidebar: false,
        route: MainMenuRouteEnum.Analytics,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
    ]
  },
  {
    id: MainMenuEnum.Notification,
    name: 'Notification',
    isOpen: false,
    hasSubMenu: true,
    hasSidebar: false,
    route: MainMenuRouteEnum.Notification,
    show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
    icon: 'notification-header-menu',
    subMenu: [
      {
        id: MainMenuEnum.Notification,
        name: 'Messages',
        hasSidebar: false,
        route: MainMenuRouteEnum.Notification,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      },
      {
        id: MainMenuEnum.NotificationList,
        name: 'Notification Template',
        hasSidebar: false,
        route: MainMenuRouteEnum.NotificationList,
        show: userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) !== -1,
      }
    ]
  },
];
