import {Injectable} from '@angular/core';
import {TokenStorageService} from '../services/token-storage.service';
import {Observable, of} from 'rxjs';
import {UserPositionEnum} from '../../../shared/enums/user-position.enum';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(private tokenStorageService: TokenStorageService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    const userProfile = this.tokenStorageService.getUser();
    if (userProfile.positions.findIndex(p => p.position === UserPositionEnum.OfficeManager) > -1) {
      return of(true);
    } else {
      this.router.navigate(['/daily-tasks']);
    }
  }
}
